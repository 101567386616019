
import { defineComponent, ref } from 'vue';
import ComponentCard from '@/components/general/molecules/ComponentCard.vue';
import CommunityButton from '../atoms/CommunityButton.vue';
import { AuthService } from '@/services/auth/AuthServices';
import { useRouter } from 'vue-router';
import { useAuthStore } from '@/stores/auth'
import CommunityRegister from "@/components/communities/organisms/adminConfig/CommunityRegister.vue";
import { CommunityService } from "@/services/communities/CommunityServices";
import ModalAlert from '@/components/general/molecules/ModalAlert.vue';
import { getEnvConfig } from "@/configs/UrlConfig";

export default defineComponent({
    props: {
        communities: {
            type: Array,
            require: true
        },
    },
    setup(props, { emit }) {
        const communityService = new CommunityService();
        const authService = new AuthService()
        const router = useRouter()
        const showAlertFlag = ref<boolean>(false)
        const authStore = useAuthStore();
        const authToken = authStore.user;
        const showModal = ref<boolean>(false);
        const success = ref<boolean>(false);
        const communeId = ref<number>(0);
        const editorView = ref(authToken?.isSuperuser)
        const title = ref<string>("")
        const message = ref<string>("")
        const isHovered = ref<boolean>(false);


        async function showCommunity(communityId: any) {
            const hasValidSession = await authService.hasValidUserSession()
            if (hasValidSession) {
                router.push({ name: 'community-general', params: { id: communityId } });
            }
            else {
                router.push({ name: 'my-communities' })
            }
        }

        const handleAccept = () => {
            console.log('0')

            showModal.value = false
        }

        const deleteCommunity = async (item: any) => {
            console.log(item);
            
            communeId.value = item.id
            title.value = "¿Desea Eliminar?"
            message.value = "¿Está seguro que desea eliminar la comunidad?"
            success.value = true

        }

        const handleAcceptSuccess = async () => {
            success.value = false;
            await communityService.deleteCommunityService(communeId.value)
            emit('deleteCommunity')
        };

        const handleCancelSuccess = () => {
            showModal.value = false;
            success.value = false;
        };

        return {
            showModal,
            showCommunity,
            showAlertFlag,
            deleteCommunity,
            editorView,
            handleAccept,
            success,
            title,
            message,
            handleAcceptSuccess,
            handleCancelSuccess,
            communeId,
            isHovered,
            getEnvConfig
        };
    },
    components: { ComponentCard, CommunityButton, CommunityRegister, ModalAlert }
})
