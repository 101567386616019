
import { ref, computed, defineComponent } from 'vue';
import { UserService } from "@/services/users/UserServices";
import ModalAlert from '@/components/general/molecules/ModalAlert.vue';
import { UserCommunity } from '@/services/users/types'
import AlertBasic from '../../general/atoms/Alert.vue'
import TableComponent from '@/components/general/molecules/TableComponent.vue';
import PermissionModal from '../molecules/PermissionModal.vue';
import PermisionModalDetail from '../molecules/PermisionModalDetail.vue';
export default defineComponent({
  props: {
    communityId: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const users = ref<UserCommunity[]>([]);
    const permissions = ref([
      { id: 1, name: 'Residente' },
      { id: 2, name: 'Trabajador' },
      { id: 3, name: 'Administrador' }
    ]);
    const currentItem = ref<UserCommunity>();
    const searchTableData = ref(['firsName', 'name']);
    const showButtons = computed(() => true)
    const searchText = ref<string>('');
    const selectedPermission = ref<string>('');
    const userService = new UserService();
    const iDinamic = ref()
    const showEmployeeRegister = ref<boolean>(false)
    const isDataLoaded = ref<boolean>(false);
    const title = ref<string>('')
    const message = ref<string>('')
    const showModal = ref<boolean>(false)
    const showTextField = ref<boolean>(true)
    const showAlert = ref<boolean>(false)
    const type = ref<string>('')
    const showModalEdit = ref<boolean>(false)
    const showDetails = ref<boolean>(false)
    const columns = ref([{ name: 'name', label: 'Nombre' }, { name: 'phone', label: 'Teléfono' }, { name: 'email', label: 'Email' }, { name: 'rol', label: 'Rol' }])

    const init = async () => {
      const response = await userService.getCommunityUsers(props.communityId);
      users.value = response.data;
      isDataLoaded.value = true;
    }
    init()
    const formattedData = computed(() => {
      return users.value.map((item) => {
        return {
          userId: item.userId,
          phone: item.user.phone,
          name: item.user.firstName + ' ' + item.user.lastName,
          email: item.user.email,
          rol: permissions.value.find((it) => it.id === item.userRole)?.name
        }
      })
    })

    const deleteTable = async (registro: any) => {
      iDinamic.value = registro.id
      title.value = '¿Desea Eliminar?'
      message.value = 'No sera posible retroceder la Acción'
      showModal.value = true
    }

    const handleCancel = () => {
      showModal.value = false;
    };

    const handleDelete = async () => {
      await userService.leaveUserCommunity(props.communityId, iDinamic.value)
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          showModal.value = false;
          init()
        });
    };

    const handleAccept = () => {
      showModal.value = false;
      showEmployeeRegister.value = false
    };
    const clear = () => {
      searchText.value = '';
      selectedPermission.value = '';
    };
    const updateTable = async (registro: any) => {
      currentItem.value = users.value.find((x) => x.userId === registro.userId)
      showModalEdit.value = true
      console.log(currentItem.value)
    }
    const datailsTable = async (registro: any) => {
      currentItem.value = users.value.find((x) => x.userId === registro.userId)
      showDetails.value = true
    }
    const buttons = ref([
      { id: 1, name: 'Editar', action: updateTable, className: ' flex items-center justify-center bg-warning text-white hover:bg-yellow-400 focus:bg-yellow-400', iconName: 'pencil' },
      { id: 2, name: 'Eliminar', action: deleteTable, className: 'bg-red-600 text-white hover:bg-red-500 focus:bg-red-500', iconName: 'delete' },
      { id: 3, name: 'Detalles', action: datailsTable, className: 'bg-success text-white hover:bg-green-400 focus:bg-green-400', iconName: 'eye-outline' },
    ])
    const handleCloseModal = () => {
      showModalEdit.value = false
      showDetails.value = false
    }
    const change = () => {
      showModalEdit.value = false
      init()
    }
    return {
      change,
      clear,
      handleCloseModal,
      handleDelete,
      handleAccept,
      handleCancel,
      users,
      permissions,
      selectedPermission,
      searchText,
      title,
      message,
      showModal,
      showTextField,
      showAlert,
      type,
      formattedData,
      columns,
      buttons,
      showButtons,
      searchTableData,
      currentItem,
      showModalEdit,
      showDetails
    }
  },
  components: { ModalAlert, AlertBasic, TableComponent, PermissionModal, PermisionModalDetail },

});
