import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-wrap" }
const _hoisted_2 = { class: "relative" }
const _hoisted_3 = { class: "absolute top-2 right-2 z-10 flex" }
const _hoisted_4 = { class: "overflow-hidden h-40 object-cover" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "grid grid-cols-12 gap-4 ml-4" }
const _hoisted_7 = { class: "col-span-12" }
const _hoisted_8 = { class: "text-left" }
const _hoisted_9 = { class: "font-bold text-2xl pt-2 pb-1" }
const _hoisted_10 = { class: "text-left ml-4 mr-4 mt-4" }
const _hoisted_11 = { class: "text-lg font-light" }
const _hoisted_12 = { class: "mt-8 ml-2 flex h-fit" }
const _hoisted_13 = { class: "flex ml-2 mr-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mdicon = _resolveComponent("mdicon")!
  const _component_CommunityButton = _resolveComponent("CommunityButton")!
  const _component_ComponentCard = _resolveComponent("ComponentCard")!
  const _component_ModalAlert = _resolveComponent("ModalAlert")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ComponentCard, {
      class: "w-80 mr-5 mt-5 h-[23rem] pointer transition duration-300 shadow-lg hover:shadow",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showBlock(_ctx.block.id))),
      onMouseenter: _cache[2] || (_cache[2] = ($event: any) => (_ctx.block.isHovered = true)),
      onMouseleave: _cache[3] || (_cache[3] = ($event: any) => (_ctx.block.isHovered = false))
    }, {
      default: _withCtx(() => [
        _createElementVNode("section", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_ctx.block.isHovered)
              ? (_openBlock(), _createBlock(_component_mdicon, {
                  key: 0,
                  name: "delete",
                  onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.deleteBlock(_ctx.block.id, _ctx.block.communityId)), ["stop"])),
                  class: "cursor-pointer px-2 py-2 rounded-full bg-red-600 text-white hover:bg-red-500 focus:bg-red-500"
                }))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("img", {
              src: `${_ctx.getEnvConfig().apiURL}/${_ctx.block.thumbnail}`,
              class: "w-full h-full object-cover"
            }, null, 8, _hoisted_5)
          ])
        ]),
        _createElementVNode("section", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.block.name), 1)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.block.address), 1)
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _createVNode(_component_CommunityButton, {
              blockId: _ctx.block.id,
              communityId: _ctx.block.communityId,
              block: _ctx.block.id,
              roundLeft: true,
              "icon-name": "account-group"
            }, null, 8, ["blockId", "communityId", "block"]),
            _createVNode(_component_CommunityButton, {
              floors: _ctx.block.totalFloors,
              communityId: _ctx.block.communityId,
              block: _ctx.block.id,
              "icon-name": "office-building-outline"
            }, null, 8, ["floors", "communityId", "block"]),
            _createVNode(_component_CommunityButton, {
              parking: _ctx.block.totalParkingSpots,
              communityId: _ctx.block.communityId,
              block: _ctx.block.id,
              roundRight: true,
              "icon-name": "car-shift-pattern"
            }, null, 8, ["parking", "communityId", "block"])
          ])
        ])
      ]),
      _: 1
    }),
    (_ctx.success)
      ? (_openBlock(), _createBlock(_component_ModalAlert, {
          key: 0,
          title: _ctx.title,
          message: _ctx.message,
          visible: _ctx.success,
          option: false,
          onAccept: _ctx.handleAcceptSuccess,
          onCancelAlert: _ctx.handleCancelSuccess
        }, null, 8, ["title", "message", "visible", "onAccept", "onCancelAlert"]))
      : _createCommentVNode("", true)
  ]))
}