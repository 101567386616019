import { Query, QueryWithOutId, unitBase, updateState } from "./types";
import {
  BaseCommunity,
  CommunitySummary,
  BaseForm,
  CreateCommunity,
  BaseAccessControl,
  Instructions,
  AccessControl,
  BlockCommunity,
  InstructionType,
} from "./types";
import { Response } from "../types";
import {
  UserCommunityData,
  FireExtinguisherBase,
  FireExtinguisherUpdate,
  GasCylinder,
  GasCylinderBase,
  GasCylinderUpdate,
  userInfo,
  WorkerInfo,
  userCreate,
  updateUsers,
} from "./types";
import { AuthenticatedService } from "../auth/AuthenticatedServices";
import { getResponse, keysToSnake } from "../utils";
import { getEnvConfig } from "@/configs/UrlConfig";

export class CommunityService extends AuthenticatedService {
  async getAdminCommunities(): Promise<Response<CommunitySummary[]>> {
    const url = `${getEnvConfig().apiURL}/community`;

    return getResponse(
      url,
      this.token,
      "GET",
      undefined,
      undefined,
      CommunitySummary
    );
  }
  async getCommunity(communityId: number): Promise<Response<CreateCommunity>> {
    // nombre
    // rut
    // dirección
    // tipo de establ
    // tipo de control de acceso
    // denominacion del personal de servicio
    // [Botones seleccionados]
    // imagen
    // nro notifiaciones
    const url = `${getEnvConfig().apiURL}/community/${communityId}`;
    return getResponse(url, this.token, "GET");
  }
  async getResidentblock(communityId: number, blockId: number) {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/blocks/${blockId}/residents`;
    return getResponse(url, this.token, "GET");
  }

  async updateCommunity(
    communityId: number,
    communityUpdate: CreateCommunity
  ): Promise<Response<CommunitySummary>> {
    const formData = new FormData();
    const ChangeImage = typeof communityUpdate.thumbnail != "string";
    formData.append("rut", communityUpdate.rut || "");
    formData.append("address", communityUpdate.address || "");
    formData.append("description", communityUpdate.description || "");
    formData.append("name", communityUpdate.name || "");
    formData.append("business_name", communityUpdate.businessName || "");
    formData.append(
      "county_id",
      communityUpdate.countyId ? communityUpdate.countyId.toString() : ""
    );
    formData.append(
      "thumbnail",
      communityUpdate.thumbnail && ChangeImage ? communityUpdate.thumbnail : ""
    );

    const url = `${getEnvConfig().apiURL}/community/${communityId}/update`;
    return getResponse(url, this.token, "POST", undefined, formData);
  }

  async createCommunity(
    communityCreate: CreateCommunity
  ): Promise<Response<CreateCommunity>> {
    const formData = new FormData();
    formData.append("rut", communityCreate.rut || "");
    formData.append("address", communityCreate.address || "");
    formData.append("description", communityCreate.description || "");
    formData.append("name", communityCreate.name || "");
    formData.append("business_name", communityCreate.businessName || "");
    formData.append(
      "county_id",
      communityCreate.countyId ? communityCreate.countyId.toString() : ""
    );
    formData.append(
      "thumbnail",
      communityCreate.thumbnail ? communityCreate.thumbnail : ""
    );

    const url = `${getEnvConfig().apiURL}/community`;
    return getResponse(url, this.token, "POST", undefined, formData);
  }

  async createPersonal(
    communityId: number,
    personnel: WorkerInfo
  ): Promise<Response<WorkerInfo>> {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/register-personnel`;
    return getResponse(url, this.token, "POST", keysToSnake(personnel));
  }

  async updatePersonal(
    communityId: number,
    communityUserId: number,
    personnel: WorkerInfo
  ): Promise<Response<WorkerInfo>> {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/personnel/${communityUserId}/update`;
    return getResponse(url, this.token, "POST", keysToSnake(personnel));
  }

  async getPersonal(communityId: number): Promise<Response<userInfo>> {
    const url = `${getEnvConfig().apiURL}/community/${communityId}/personnel`;
    return getResponse(url, this.token, "GET");
  }

  async deletePersonalService(communityId: number, communityUserId: number) {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/community-user/${communityUserId}/delete`;
    return getResponse(url, this.token, "DELETE");
  }

  async deleteCommunityService(communityId: number) {
    const url = `${getEnvConfig().apiURL}/community/${communityId}/delete`;
    return getResponse(
      url,
      this.token,
      "DELETE",
      undefined,
      undefined,
      undefined
    );
  }

  async getCommunityNotifications(token: string, communityId: number) {
    // Todas las notificaciones
    return;
  }

  async getCommunityBlocks(communityId: number) {
    const url = `${getEnvConfig().apiURL}/community/${communityId}/blocks`;
    return getResponse(url, this.token, "GET");
  }

  async getFormQuestionBlockService(
    communityId: number,
    blocksId: number
  ): Promise<Response<BaseForm>> {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/blocks/${blocksId}/questionnaire`;
    return getResponse(url, this.token, "GET");
  }

  async createQueryByCommunity(communityId: number, query: QueryWithOutId) {
    const url = `${getEnvConfig().apiURL}/community/${communityId}/queries`;
    return getResponse(url, this.token, "POST", query);
  }

  async getQueriesByCommunity(communityId: number) {
    const url = `${getEnvConfig().apiURL}/community/${communityId}/queries`;

    return getResponse(url, this.token, "GET");
  }

  async getQueries() {
    const url = `${getEnvConfig().apiURL}/queries/all`;

    return getResponse(url, this.token, "GET", undefined, undefined, Query);
  }

  async getQueryById(QueryId: number, communityId: number) {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/queries/${QueryId}`;

    return getResponse(url, this.token, "GET", undefined, undefined, Query);
  }

  async updateQuery(
    communityId: number,
    query: QueryWithOutId,
    queryId: number
  ) {
    const url = `${
      getEnvConfig().apiURL
    }/queries/community/${communityId}/query/${queryId}/edit`;

    return getResponse(url, this.token, "POST", query, undefined, Query);
  }

  async deleteQuery(communityId: number, QueryId: number) {
    const url = `${getEnvConfig().apiURL}/queries/query/${QueryId}/delete`;

    return getResponse(url, this.token, "DELETE", undefined, undefined, Query);
  }

  async getCommunit(token: string, communityId: number) {
    // nombre
    // rut
    // dirección
    // tipo de establ
    // tipo de control de acceso
    // denominacion del personal de servicio
    // [Botones seleccionados]
    // imagen
    // nro notifiaciones
    return;
  }

  // servicios de block Community
  async getBlocksCommunity(communityId: number) {
    const url = `${getEnvConfig().apiURL}/community/${communityId}/blocks`;
    return getResponse(url, this.token, "GET");
  }

  async getBlock(communityId: number, blockId: number) {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/block/${blockId}`;
    return getResponse(url, this.token, "GET");
  }

  async deleteBlockCommunityService(communityId: number, blockId: number) {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/block/${blockId}/delete`;
    return getResponse(
      url,
      this.token,
      "DELETE",
      undefined,
      undefined,
      undefined
    );
  }

  async updateBlock(
    communityId: number,
    blockId: number,
    blockUpdate: BlockCommunity
  ): Promise<Response<BlockCommunity>> {
    const formData = new FormData();
    // const ChangeImage = typeof communityUpdate.thumbnail != 'string'
    formData.append("name", blockUpdate.name || "");
    formData.append("address", blockUpdate.address || "");
    formData.append("total_units", blockUpdate.totalUnits?.toString() || "");
    formData.append(
      "total_visitor_parking_spots",
      blockUpdate.totalVisitorParkingSpots?.toString() || ""
    );
    formData.append(
      "total_parking_spots",
      blockUpdate.totalParkingSpots?.toString() || ""
    );
    formData.append(
      "total_warehouse_spaces",
      blockUpdate.totalWarehouseSpaces?.toString() || ""
    );
    formData.append(
      "total_commercial_premises",
      blockUpdate.totalCommercialPremises?.toString() || ""
    );
    formData.append(
      "total_undeground_levels",
      blockUpdate.totalUndegroundLevels?.toString() || ""
    );
    formData.append("gas_yype", blockUpdate.gasType?.toString() || "");
    formData.append(
      "municipal_acceptance_date",
      blockUpdate.municipalAcceptanceDate?.toString() || ""
    );
    formData.append(
      "construction_year",
      blockUpdate.constructionYear?.toString() || ""
    );
    formData.append(
      "construction_company",
      blockUpdate.constructionCompany || ""
    );
    formData.append("realState_agency", blockUpdate.realStateAgency || "");
    formData.append("rut", blockUpdate.rut || "");
    formData.append("company_name", blockUpdate.companyName || "");
    formData.append("total_floors", blockUpdate.totalFloors?.toString() || "");

    // formData.append('thumbnail', communityUpdate.thumbnail && ChangeImage ? communityUpdate.thumbnail : '')

    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/block/${blockId}/update`;
    return getResponse(url, this.token, "POST", undefined, formData);
  }

  async createBlock(
    communityId: number,
    block: BlockCommunity
  ): Promise<Response<BlockCommunity>> {
    const form = new FormData();
    console.log(block);
    const ChangeImage = typeof block.thumbnail != "string";

    form.append("name", block.name?.toString() || "");
    form.append("address", block.address?.toString() || "");
    form.append("total_floors", block.totalFloors?.toString() || "");
    form.append("total_units", block.totalUnits?.toString() || "");
    form.append(
      "total_visitor_parking_spots",
      block.totalVisitorParkingSpots?.toString() || ""
    );
    form.append(
      "total_parking_spots",
      block.totalParkingSpots?.toString() || ""
    );
    form.append(
      "total_warehouse_spaces",
      block.totalWarehouseSpaces?.toString() || ""
    );
    form.append(
      "total_commercial_premises",
      block.totalCommercialPremises?.toString() || ""
    );
    form.append(
      "total_undeground_levels",
      block.totalUndegroundLevels?.toString() || ""
    );
    form.append("gas_type", block.gasType?.toString() || "");
    form.append(
      "municipal_acceptance_date",
      block.municipalAcceptanceDate?.toISOString() || ""
    );
    form.append("construction_year", block.constructionYear?.toString() || "");
    form.append(
      "structural_calculations",
      block.structuralCalculations?.toString() || ""
    );
    form.append(
      "construction_company",
      block.constructionCompany?.toString() || ""
    );
    form.append("real_state_agency", block.realStateAgency?.toString() || "");
    form.append("rut", block.rut?.toString() || "");
    form.append("company_name", block.companyName?.toString() || "");
    form.append(
      "thumbnail",
      block.thumbnail && ChangeImage ? block.thumbnail : ""
    );

    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/blocks/create`;
    return getResponse(url, this.token, "POST", undefined, form);
  }

  async createFillQuestionnaire(
    communityId: number,
    blocksId: number,
    answer: any
  ): Promise<Response<BaseForm>> {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/blocks/${blocksId}/fill-questionnaire`;
    return getResponse(url, this.token, "POST", answer);
  }

  async addQuestionBlock(communityId: number, blockId: number, formId: number) {
    const params = {
      form_id: formId,
    };
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/blocks/${blockId}/add-questionnaire`;
    return getResponse(url, this.token, "POST", params);
  }

  // servicios de control de acceso
  async createFireExtinguisher(
    communityId: number,
    blockId: number,
    fireExtinguisher: FireExtinguisherBase
  ) {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/blocks/${blockId}/fire-extinguishers/create`;
    return getResponse(url, this.token, "POST", keysToSnake(fireExtinguisher));
  }

  async getFireExtinguishers(communityId: number) {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/fire-extinguishers`;
    return getResponse(url, this.token, "GET");
  }
  async updateFireExtinguisher(
    communityId: number,
    blockId: number,
    extinguisherId: number,
    fireExtinguisher: FireExtinguisherUpdate
  ) {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/blocks/${blockId}/fire-extinguishers/${extinguisherId}/update`;
    return getResponse(url, this.token, "POST", keysToSnake(fireExtinguisher));
  }
  async deleteFireExtinguisher(
    communityId: number,
    blockId: number,
    fireExtinguisherId: number
  ) {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/blocks/${blockId}/fire-extinguishers/${fireExtinguisherId}/delete`;
    return getResponse(url, this.token, "DELETE");
  }
  async getGasCylindersCommunity(
    communidadId: number
  ): Promise<Response<GasCylinder[]>> {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communidadId}/gas-cylinders`;
    return getResponse(url, this.token, "GET");
  }

  async createGasCylinder(
    communityId: number,
    unitId: number,
    gasCylinder: GasCylinderBase
  ) {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/units/${unitId}/gas-cylinders/create`;
    return getResponse(url, this.token, "POST", keysToSnake(gasCylinder));
  }

  async createResidentInCommunity(communityId: number, resident: userCreate) {
    console.log("2");
    console.log(communityId);
    const url = `${getEnvConfig().apiURL}/community/register-and-join`;
    return getResponse(url, this.token, "POST", keysToSnake(resident));
  }

  async updateUsersCommunity(
    communityId: number,
    communityUserId: number,
    user: updateUsers
  ) {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/residents/${communityUserId}/update`;
    return getResponse(url, this.token, "POST", keysToSnake(user));
  }

  async updateGasCylinder(
    communityId: number,
    blockId: number,
    gasCylinderId: number,
    gasCylinder: GasCylinderUpdate
  ) {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/blocks/${blockId}/gas-cylinders/${gasCylinderId}/update`;
    return getResponse(url, this.token, "POST", keysToSnake(gasCylinder));
  }
  async updateUsersCommunityActive(communityId: number, user: updateState) {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/activate-users`;
    return getResponse(url, this.token, "POST", user);
  }
  async deleteGasCylinder(
    communityId: number,
    block_id: number,
    gasCylinderId: number
  ) {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/blocks/${block_id}/gas-cylinders/${gasCylinderId}/delete`;
    return getResponse(url, this.token, "DELETE");
  }

  async getCommunityUnit(communityId: number) {
    const url = `${getEnvConfig().apiURL}/community/${communityId}/units`;
    return getResponse(url, this.token, "GET");
  }
  async createCommunityUnit(communityId: number, unit: unitBase) {
    const url = `${getEnvConfig().apiURL}/community/${communityId}/unit/create`;
    return getResponse(url, this.token, "POST", keysToSnake(unit));
  }
  async updateUnit(communityId: number, unitId: number, unit: unitBase) {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/unit/${unitId}/update`;
    return getResponse(url, this.token, "POST", keysToSnake(unit));
  }
  async createAccessControlByCommunity(
    communityId: number,
    AccessControl: BaseAccessControl
  ) {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/create-access-control`;
    return getResponse(url, this.token, "POST", keysToSnake(AccessControl));
  }
  async getAccessControlByCommunity(communityId: number) {
    //GET/community/{community_id}/access-control/all
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/access-control/all`;

    return getResponse(url, this.token, "GET");
  }
  async getAllAccessControl(communityId: number) {
    const url = `${
      getEnvConfig().apiURL
    }/community-extras/${communityId}/access-control/all`;
    return getResponse(url, this.token, "GET");
  }
  async deleteAccessControl(communityId: number, accessControlId: number) {
    // /community-extras/{community_id}/access-control/{access_control_id}/delete
    const url = `${
      getEnvConfig().apiURL
    }/community-extras/${communityId}/access-control/${accessControlId}/delete`;
    return getResponse(url, this.token, "DELETE");
  }
  async updateAccessControl(
    communityId: number,
    accessControlId: number,
    Access_control: BaseAccessControl
  ) {
    ///community-extras/{community_id}/access-control/{access_control_id}/edit
    const url = `${
      getEnvConfig().apiURL
    }/community-extras/${communityId}/access-control/${accessControlId}/edit`;
    return getResponse(url, this.token, "POST", Access_control);
  }
  async getAcessControlByBlock(blockId: number, communityId: number) {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/block/${blockId}/access-control/all`;
    return getResponse(url, this.token, "GET");
  }
  async createAccessControlByBlock(
    communityId: number,
    blockId: number,
    AccessControl: BaseAccessControl
  ) {
    ///community/{community_id}/block/{block_id}/access-control/create-new
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/block/${blockId}/access-control/create-new`;
    return getResponse(url, this.token, "POST", keysToSnake(AccessControl));
  }

  async deleteUserCommunity(communityId: number, user_in_community_id: number) {
    const url = `${getEnvConfig().apiURL}/community/${communityId}/remove-user`;
    const params = {
      user_in_community_id: user_in_community_id,
    };
    return getResponse(url, this.token, "DELETE", params);
  }
  async deleteUserBlock(
    communityId: number,
    blockId: number,
    user_in_community_id: number
  ) {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/block/${blockId}/remove`;
    const params = {
      user_in_community_id: user_in_community_id,
    };
    return getResponse(url, this.token, "DELETE", params);
  }
  async getUnitsCommunity(communityId: number) {
    const url = `${getEnvConfig().apiURL}/community/${communityId}/units`;
    return getResponse(url, this.token, "GET");
  }

  async getBlockIntructions(
    communityId: number,
    blockId: number,
    emergencyTypeId: number,
    emergencyType: InstructionType
  ): Promise<Response<Instructions[]>> {
    // /community/{community_id}/blocks/{block_id}/instruction-sets/{emergency_type_id}/instructions
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/blocks/${blockId}/instruction-sets/${emergencyTypeId}/instructions`;
    return getResponse(url, this.token, "POST", keysToSnake(emergencyType));
  }
}
