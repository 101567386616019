import { Response } from "../types";
import { UserCommunityData, UserCreate, UserComunityId, Pets, PetsInfo,UserRole } from "./types";
import { AuthenticatedService } from "../auth/AuthenticatedServices";
import { getResponse, keysToSnake } from "../utils";
import { getEnvConfig } from "@/configs/UrlConfig";

export class UserService extends AuthenticatedService {

    async getCommunityResidents(
        communityId: number
    ) {
        const url = `${getEnvConfig().apiURL}/community/${communityId}/residents`;
        return getResponse(url, this.token, "GET");
    }
    async getCommunityUsers(communityId: number) {
        const url = `${getEnvConfig().apiURL}/community/${communityId}/all-users`;
        return getResponse(url, this.token, "GET");
    }

    async leaveUserCommunity(communityId: number, userId: number) {
        const params = keysToSnake(
            {
                userId
            }
        )
        const url = `${getEnvConfig().apiURL
            }/community/${communityId}/remove_user_community`;
        return getResponse(url, this.token, "DELETE", params, undefined);
    }

    async changeUserDetails(
        id: number,
        communityId: number,
        userId: number,
        userRole: number,
        parkingSpace: string,
        warehouseSpace: string
    ) {
        const params = keysToSnake(
            {
                id, userId, userRole, parkingSpace, warehouseSpace
            }
        )
        const url = `${getEnvConfig().apiURL
            }/community/${communityId}/edit-user`;
        return getResponse(url, this.token, "POST", params, undefined);
    }
    async getUserInfo(userId: number) {
        const url = `${getEnvConfig().apiURL}/auth/${userId}`;
        return getResponse(url, this.token, "GET");
    }
    async getCommunityPersonal(communityId: number) {
        const url = `${getEnvConfig().apiURL}/community/${communityId}/personal`;
        return getResponse(url, this.token, "GET");
    }
    async createUser(user: UserCreate) {
        const url = `${getEnvConfig().apiURL}/auth/register`;
        return getResponse(url, this.token, "POST", keysToSnake(user));
    }
    async updateUser(user: UserCreate) {
        const url = `${getEnvConfig().apiURL}/auth/update`;
        return getResponse(url, this.token, "POST", keysToSnake(user));
    }
    async joinUserCommunity(communityId: number, userId: number, User: UserComunityId) {
        // /community/{community_id}/join/{user_id}
        const url = `${getEnvConfig().apiURL}/community/${communityId}/join`;
        return getResponse(url, this.token, "POST", keysToSnake(User));
    }
    async joinUserBlock(communityId: number, blockId: number, user_in_community_id: number) {
        const url = `${getEnvConfig().apiURL}/community/${communityId}/block/${blockId}/join`;
        const params =
        {
            community_user_id: user_in_community_id
        }

        return getResponse(url, this.token, "POST", params);
    } async getPets(communityId: number) {
        const url = `${getEnvConfig().apiURL}/community/${communityId}/user-pets`;
        return getResponse(url, this.token, "GET");
    }
    async createPets(communityId: number, pets: Pets) {
        const url = `${getEnvConfig().apiURL}/community/${communityId}/add-pet`;
        return getResponse(url, this.token, "POST", keysToSnake(pets));
    }
    async deletePets(communityId: number, petId: number) {
        const url = `${getEnvConfig().apiURL}/community/${communityId}/pet/${petId}/delete`;
        return getResponse(url, this.token, "DELETE");
    }
    async updatePets(communityId: number, petId: number, pets: PetsInfo) {
        const url = `${getEnvConfig().apiURL}/community/${communityId}/pet/${petId}/update`;
        return getResponse(url, this.token, "POST", keysToSnake(pets));
    }

    async changeRole(communityId: number, userRole:UserRole) {
        // /community/{community_id}/users/change-rol
        const url = `${getEnvConfig().apiURL}/community/${communityId}/users/change-rol`;
        return getResponse(url, this.token, "POST", keysToSnake(userRole));
    }

}