import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, withCtx as _withCtx, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "flex my-4" }
const _hoisted_2 = {
  key: 1,
  class: "my-4"
}
const _hoisted_3 = { class: "flex justify-between mt-4" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = { class: "flex px-4 py-2 text-gray-700" }
const _hoisted_6 = {
  key: 0,
  class: "flex flex-col w-full"
}
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "flex justify-end" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = ["disabled", "onClick"]
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { class: "flex justify-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AddButton = _resolveComponent("AddButton")!
  const _component_TextField = _resolveComponent("TextField")!
  const _component_Accordion = _resolveComponent("Accordion")!
  const _component_ModalAlert = _resolveComponent("ModalAlert")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Modal, {
      title: `Detalles del formulario`,
      onOnClose: _ctx.closeModal,
      visible: _ctx.showModalAdd,
      "onUpdate:visible": _cache[11] || (_cache[11] = (e) => _ctx.showModalAdd = e)
    }, {
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_12, [
          (!_ctx.isAdd)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args))),
                class: "px-4 py-2 mr-2 bg-gray-300 hover:bg-gray-400 text-white focus:bg-gray-400 rounded-md"
              }, " Cerrar "))
            : _createCommentVNode("", true),
          (!_ctx.isAdd)
            ? (_openBlock(), _createElementBlock("button", {
                key: 1,
                onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.saveAddItem && _ctx.saveAddItem(...args))),
                class: "px-4 py-2 bg-success text-white hover:bg-green-700 focus:bg-green-700 rounded-md"
              }, " Aceptar "))
            : _createCommentVNode("", true)
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleButton('resident'))),
            class: _normalizeClass([{
                    'bg-success': _ctx.activeButton === 'resident',
                    'bg-gray-300': _ctx.activeButton !== 'resident',
                    'text-white': _ctx.activeButton === 'resident',
                    'text-gray-800': _ctx.activeButton !== 'resident',
                    'rounded-tl-md': _ctx.isFirstButton,
                    'rounded-bl-md': _ctx.isFirstButton,
                }, "flex-1 border px-4 py-2 rounded-tl-md rounded-bl-md focus:outline-none"])
          }, " Resident ", 2),
          _createElementVNode("button", {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toggleButton('personal'))),
            class: _normalizeClass([{
                    'bg-success': _ctx.activeButton === 'personal',
                    'bg-gray-300': _ctx.activeButton !== 'personal',
                    'text-white': _ctx.activeButton === 'personal',
                    'text-gray-800': _ctx.activeButton !== 'personal',
                    'rounded-tr-md': _ctx.isLastButton,
                    'rounded-br-md': _ctx.isLastButton,
                }, "flex-1 border px-4 py-2 rounded-tr-md rounded-br-md focus:outline-none"])
          }, " Personal ", 2)
        ]),
        (!_ctx.isAdd)
          ? (_openBlock(), _createBlock(_component_AddButton, {
              key: 0,
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isAdd = true))
            }))
          : _createCommentVNode("", true),
        (_ctx.isAdd)
          ? (_openBlock(), _createElementBlock("section", _hoisted_2, [
              _createVNode(_component_TextField, {
                label: "Titulo",
                model: _ctx.title,
                "onUpdate:model": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.title) = $event)),
                type: "text",
                name: "titulo",
                placeholder: ''
              }, null, 8, ["model"]),
              _createVNode(_component_TextField, {
                label: "Descripción",
                model: _ctx.description,
                "onUpdate:model": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.description) = $event)),
                type: "textarea",
                name: "descripcion"
              }, null, 8, ["model"]),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("button", {
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.isAdd = false)),
                  class: "bg-gray-300 hover:bg-gray-400 text-white font-bold py-2 px-4 rounded"
                }, "Cancelar"),
                _createElementVNode("button", {
                  disabled: !_ctx.isFieldsFilled,
                  class: _normalizeClass([{ 'opacity-50 cursor-not-allowed disabled:pointer-events-none': !_ctx.isFieldsFilled }, "bg-success hover:bg-green-700 text-white font-bold py-2 px-4 rounded"]),
                  onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.addItem && _ctx.addItem(...args)))
                }, "Agregar", 10, _hoisted_4)
              ])
            ]))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredItems, (item, index) => {
          return (_openBlock(), _createElementBlock("section", {
            key: index,
            class: "my-4"
          }, [
            _createVNode(_component_Accordion, {
              items: [item],
              headerColor: 'bg-success',
              isEditing: _ctx.isEditing
            }, _createSlots({
              content: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, [
                  (_ctx.isEditing)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        _createVNode(_component_TextField, {
                          label: "Descripción",
                          model: _ctx.editedDescription,
                          "onUpdate:model": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.editedDescription) = $event)),
                          type: "textarea",
                          name: "descripcion"
                        }, null, 8, ["model"])
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        _createElementVNode("p", null, _toDisplayString(item.content), 1)
                      ]))
                ])
              ]),
              footer: _withCtx(() => [
                _createElementVNode("div", _hoisted_8, [
                  (!_ctx.isEditing)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        onClick: ($event: any) => (_ctx.edit(index)),
                        class: "px-4 mr-2 py-2 mb-2 text-sm font-medium text-white bg-success rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-success"
                      }, " Editar ", 8, _hoisted_9))
                    : _createCommentVNode("", true),
                  (_ctx.isEditing)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 1,
                        disabled: !_ctx.isFieldsFilledEdit,
                        class: _normalizeClass([{ 'opacity-50 cursor-not-allowed disabled:pointer-events-none': !_ctx.isFieldsFilledEdit }, "px-4 mr-2 py-2 mb-2 text-sm font-medium text-white bg-success rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-success"]),
                        onClick: ($event: any) => (_ctx.handleEdit(index))
                      }, " Guardar ", 10, _hoisted_10))
                    : _createCommentVNode("", true),
                  _createElementVNode("button", {
                    onClick: ($event: any) => (_ctx.deleteAlert(index)),
                    class: "px-4 py-2 mb-2 text-sm font-medium text-white bg-danger rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-danger"
                  }, " Eliminar ", 8, _hoisted_11)
                ])
              ]),
              _: 2
            }, [
              (_ctx.isEditing)
                ? {
                    name: "title",
                    fn: _withCtx(() => [
                      _createVNode(_component_TextField, {
                        class: "flex w-full py-2 text-gray-700",
                        compact: true,
                        label: "",
                        model: _ctx.editedTitle,
                        "onUpdate:model": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.editedTitle) = $event)),
                        type: "text",
                        name: "titulo",
                        placeholder: ''
                      }, null, 8, ["model"])
                    ]),
                    key: "0"
                  }
                : undefined
            ]), 1032, ["items", "isEditing"])
          ]))
        }), 128)),
        (_ctx.showModalAlert)
          ? (_openBlock(), _createBlock(_component_ModalAlert, {
              key: 2,
              visible: _ctx.showModalAlert,
              message: '¿Está seguro de eliminar este detalle?',
              title: 'Eliminar detalle',
              onAccept: _ctx.handleDelete
            }, null, 8, ["visible", "onAccept"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["onOnClose", "visible"])
  ]))
}