
  import { defineComponent, ref } from 'vue';
  import Modal from '@/components/general/molecules/Modal.vue';
  import TextField from '@/components/general/atoms/TextField.vue';
  import { CommunityService } from '@/services/communities/CommunityServices';
  import { useRoute } from 'vue-router';
  import { WorkerInfo } from '@/services/communities/types';
  import ModalAlert from '@/components/general/molecules/ModalAlert.vue';
  import { validateEmailStructure } from '@/services/auth/AuthUtils'
  
  export default defineComponent({
    emit: ["update:visible", "update"],
    props: {
      action: {
        type: String,
        required: true,
      },
      visible: {
        type: Boolean,
        default: false,
      },
      commiteUser: {
        type: Object,
        required: false,
      },
    },
    setup(props, { emit }) {
      const route = useRoute();
      const communityId = ref<number>(typeof route.params.id === 'string' ? parseInt(route.params.id) : 0);
      const communityServices = new CommunityService();
      const workerUserData = ref<WorkerInfo>({
        user_info: {
          firstName: '',
          lastName: '',
          phone: '',
          email: ''
        },
        communityUserEdit: {
          position: ''
        }
      });
      const hasError = ref(false)
      const hasErrorNombre = ref<boolean>(false);
      const hasErrorApellido = ref<boolean>(false);
      const hasErrorEmail = ref<boolean>(false);
      const message = ref<string>('');
      const loading = ref<boolean>(false);
      const showAlert = ref<boolean>(false);
      const hasPassError = ref(false)
      const showModalAdd = ref<boolean>(props.visible);
      const showModalAlert = ref<boolean>(false)
      const emptyFields = ref(false)
  
      const closeModal = () => {
        showModalAlert.value = true
        showModalAdd.value = false;
        emit('closeModal');
      };
  
      const init = () => {
        if (props.action === 'update' && props.commiteUser) {
          workerUserData.value.user_info.firstName = props.commiteUser.firstName
          workerUserData.value.user_info.lastName = props.commiteUser.lastName
          workerUserData.value.user_info.email = props.commiteUser.email
          workerUserData.value.user_info.phone = props.commiteUser.phone
          workerUserData.value.communityUserEdit.position = props.commiteUser.position
        }
      };
      init();
  
      const validate = () => {
        let valid = true
        if (!workerUserData.value.user_info.firstName || !workerUserData.value.user_info.lastName || !workerUserData.value.user_info.phone
          || !workerUserData.value.user_info.email) {
  
          hasErrorNombre.value = workerUserData.value.user_info.firstName ? false : true
          hasErrorApellido.value = workerUserData.value.user_info.lastName ? false : true
          hasErrorEmail.value = workerUserData.value.user_info.email ? false : true
          emptyFields.value = true
          valid = false
        }
  
        const email = workerUserData.value.user_info.email ?? ''
        if (!validateEmailStructure(email)) {
          valid = false
          emptyFields.value = true
        }
  
        return valid
      }
      const saveUser = async () => {
        const isValidated = validate()
        if (isValidated) {
          loading.value = false
          emptyFields.value = false
          if (props.action === 'create') {
            communityServices.createPersonal(communityId.value, workerUserData.value).then((response) => {
              if (response.code === 201) {
                showModalAlert.value = true
              }
            });
          } else if (props.action === 'update' && props.commiteUser) {
            const id = props.commiteUser.userCommunityID
            communityServices.updatePersonal(communityId.value, id, workerUserData.value)
              .then((response) => {
                if (response.code === 204) {
                  loading.value = false
                  showModalAlert.value = true
                }
              });
          }
        }
  
      };
  
      const handleAccept = () => {
        console.log('1');
        emit('closeAction')
      };
  
      return {
        handleAccept,
        closeModal,
        showModalAdd,
        message,
        loading,
        showAlert,
        workerUserData,
        saveUser,
        hasError,
        hasPassError,
        emptyFields,
        showModalAlert,
        hasErrorNombre,
        hasErrorApellido,
        hasErrorEmail
  
      };
    },
    components: { Modal, TextField, ModalAlert },
  });
  
  