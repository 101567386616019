
import { defineComponent, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { AuthService } from "@/services/auth/AuthServices";
import { useAuthStore } from "@/stores/auth";
import TextField from "@/components/general/atoms/TextField.vue";
import RecoverAccount from "@/components/communities/molecules/RecoverAccount.vue";
import AppButton from "@/components/general/atoms/AppButton.vue";
import Alert from "@/components/general/atoms/Alert.vue";

export default defineComponent({
  components: {
    TextField,
    RecoverAccount,
    AppButton,
    Alert,
  },
  setup() {
    const router = useRouter();
    const authService = new AuthService();
    const authStore = useAuthStore();
    const email = ref("");
    const password = ref("");
    const hasError = ref(false);
    const loading = ref(false);
    const errorMessage = ref("Credenciales inválidas");
    const recoverPassword = ref(false);
    const message = ref<string>("");
    const type = ref<string>("");
    const showAlert = ref<boolean>(false);
    watch(showAlert, (newValue) => {
      if (newValue) {
        setTimeout(() => {
          showAlert.value = false;
        }, 2000);
      }
    });
    async function login() {
      hasError.value = false;
      loading.value = true;
      const response = await authService.login(email.value, password.value);
      loading.value = false;
      if (!response.success) {
        hasError.value = true;
        return;
      }

      authStore.setToken(response.data.token);
      let ress = await authService.hasValidUserSession();
      if (authStore.user?.isSuperuser) {
        router.push("my-communities");
      } else {
        message.value = "Este usuario no tiene las autorizaciones para acceder";
        showAlert.value = true;
        type.value = "warning";
      }
    }

    function showRecoverPasswordModal() {
      recoverPassword.value = true;
    }

    function goToRegisterView() {
      router.push({ name: "register" });
    }

    return {
      recoverPassword,
      email,
      password,
      errorMessage,
      hasError,
      message,
      showAlert,
      type,
      login,
      showRecoverPasswordModal,
      goToRegisterView,
    };
  },
});
