import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-col w-full gap-2" }
const _hoisted_2 = { class: "w-full inline-flex justify-between" }
const _hoisted_3 = {
  key: 0,
  class: "self-end w-full flex justify-end mr-4"
}
const _hoisted_4 = { class: "flex items-center overflow-x-auto mb-4" }
const _hoisted_5 = {
  key: 0,
  class: "relative mx-4"
}
const _hoisted_6 = { class: "absolute top-0 left-0 flex items-center h-full ml-2" }
const _hoisted_7 = { class: "border-collapse w-full h-full border-spacing-2" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = ["disabled", "checked"]
const _hoisted_10 = { class: "sticky top-0 h-2/3 z-20 border" }
const _hoisted_11 = {
  key: 0,
  class: "sticky right-0 top-0 bg-white z-10 overflow-x-auto ml-4"
}
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { key: 0 }
const _hoisted_14 = ["onClick", "checked"]
const _hoisted_15 = {
  key: 1,
  class: "sticky right-0 flex items-center justify-center z-10 h-full"
}
const _hoisted_16 = {
  key: 1,
  class: "flex bg-white w-full justify-center rounded-md button-container pl-8"
}
const _hoisted_17 = {
  key: 0,
  class: "sticky left-[47%] mt-7 flex"
}
const _hoisted_18 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AddButton = _resolveComponent("AddButton")!
  const _component_mdicon = _resolveComponent("mdicon")!
  const _component_Selector = _resolveComponent("Selector")!
  const _component_ComponentCard = _resolveComponent("ComponentCard")!

  return (_openBlock(), _createBlock(_component_ComponentCard, {
    class: "flex h-5/6 p-2 w-full",
    title: _ctx.title
  }, {
    header: _withCtx(() => [
      _createElementVNode("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "extra-header", { class: "right-2" }, () => [
            (_ctx.sectionAddButton)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_AddButton, {
                    section: _ctx.sectionAddButton,
                    action: 'create',
                    onCloseAction: _ctx.closeAction
                  }, null, 8, ["section", "onCloseAction"])
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("section", _hoisted_4, [
          (_ctx.searchTableData)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchText) = $event)),
                  class: "w-64 pl-8 pr-4 py-2 border rounded-xl focus:outline-success focus:border-success transition-colors duration-300",
                  placeholder: "Buscar"
                }, null, 512), [
                  [_vModelText, _ctx.searchText]
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_mdicon, { name: "account-search" })
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.checkNotification.length > 0)
            ? (_openBlock(), _createElementBlock("button", {
                key: 1,
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.updateUserActive && _ctx.updateUserActive(...args))),
                class: "bg-white border-2 border-success text-success font-semibold px-4 py-1 rounded-xl ml-5 mr-5 hover:bg-green-200"
              }, " Activar Usuarios Seleccionados "))
            : _createCommentVNode("", true),
          (_ctx.isSelectPage === true)
            ? (_openBlock(), _createBlock(_component_Selector, {
                key: 2,
                items: _ctx.viewPageSelect,
                selectedItem: Number(_ctx.typeRange),
                placeHolder: 'Seleccione N° filas',
                onUpdate: _ctx.onUpdateModel
              }, null, 8, ["items", "selectedItem", "onUpdate"]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("table", _hoisted_7, [
        _createElementVNode("thead", null, [
          (_ctx.showCheckAll)
            ? (_openBlock(), _createElementBlock("th", _hoisted_8, [
                _createElementVNode("input", {
                  type: "checkbox",
                  disabled: _ctx.filteredItems.length === 0,
                  onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.selectAll && _ctx.selectAll(...args))),
                  checked: _ctx.checkNotification.length === _ctx.filteredItems.length
                }, null, 40, _hoisted_9)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("tr", _hoisted_10, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column) => {
              return (_openBlock(), _createElementBlock("th", {
                key: column.id,
                class: "py-2 px-4 min-w-[13rem] h-3/6 text-left bg-white z-30 border"
              }, _toDisplayString(column.label), 1))
            }), 128)),
            (_ctx.showButtons)
              ? (_openBlock(), _createElementBlock("th", _hoisted_11, " Acciones "))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredItems, (item) => {
            return (_openBlock(), _createElementBlock("tr", {
              key: item.id,
              class: _normalizeClass([{ 'bg-blue-200': item === _ctx.selectedRow, 'border': true }, "mb-2"]),
              onClick: ($event: any) => (_ctx.selectRow(item))
            }, [
              (_ctx.showCheckAll)
                ? (_openBlock(), _createElementBlock("td", _hoisted_13, [
                    _createElementVNode("input", {
                      type: "checkbox",
                      onClick: ($event: any) => (_ctx.saveUser(item.userInCommunity)),
                      checked: _ctx.checkNotification.includes(item.userInCommunity)
                    }, null, 8, _hoisted_14)
                  ]))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column) => {
                return (_openBlock(), _createElementBlock("td", {
                  key: column.id,
                  class: "py-2 px-4 border text-sm"
                }, _toDisplayString(item[column.name]), 1))
              }), 128)),
              (_ctx.showButtons)
                ? (_openBlock(), _createElementBlock("td", _hoisted_15, [
                    (item.userRoleWorkers && item.userRoleWorkers != 3)
                      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.buttons, (button) => {
                          return (_openBlock(), _createBlock(_component_mdicon, {
                            class: _normalizeClass(["z-10", ['mr-2 cursor-pointer px-2 py-2 rounded-md z-10', button.className]]),
                            name: button.iconName,
                            key: button.id,
                            onClick: _withModifiers(($event: any) => (button.action(item)), ["stop"])
                          }, null, 8, ["name", "class", "onClick"]))
                        }), 128))
                      : _createCommentVNode("", true),
                    (!item.userRoleWorkers)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buttons, (button) => {
                            return (_openBlock(), _createBlock(_component_mdicon, {
                              name: button.iconName,
                              key: button.id,
                              class: _normalizeClass(['mr-2 cursor-pointer px-1 py-1 rounded-md z-20', button.className]),
                              onClick: _withModifiers(($event: any) => (button.action(item)), ["stop"])
                            }, null, 8, ["name", "class", "onClick"]))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              _renderSlot(_ctx.$slots, "default", { item: item })
            ], 10, _hoisted_12))
          }), 128))
        ]),
        (_ctx.matches)
          ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
              _createVNode(_component_mdicon, {
                class: "rounded-l-lg border-l-1 cursor-pointer bg-success text-white hover:bg-green-500 focus:bg-green-500",
                name: "chevron-double-left",
                disabled: _ctx.currentPage === 1,
                onClick: _ctx.firstPage
              }, null, 8, ["disabled", "onClick"]),
              _createVNode(_component_mdicon, {
                class: "border-l-1 cursor-pointer bg-success text-white hover:bg-green-500 focus:bg-green-500",
                name: "chevron-left",
                disabled: _ctx.currentPage === 1,
                onClick: _ctx.previousPage
              }, null, 8, ["disabled", "onClick"]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visiblePages, (page) => {
                return (_openBlock(), _createElementBlock("span", {
                  key: page,
                  class: _normalizeClass(['cursor-pointer', 'px-3', 'bg-success text-white hover:bg-green-500 focus:bg-green-500', { 'bg-green-300 text-white': _ctx.currentPage === page, 'bg-success text-white': _ctx.currentPage !== page }]),
                  onClick: ($event: any) => (_ctx.goToPage(page))
                }, _toDisplayString(page), 11, _hoisted_18))
              }), 128)),
              _createVNode(_component_mdicon, {
                class: "cursor-pointer bg-success text-white hover:bg-green-500 focus:bg-green-500",
                name: "chevron-right",
                disabled: _ctx.currentPage === _ctx.totalPages,
                onClick: _ctx.nextPage
              }, null, 8, ["disabled", "onClick"]),
              _createVNode(_component_mdicon, {
                class: "rounded-r-lg cursor-pointer bg-success text-white hover:bg-green-500 focus:bg-green-500",
                name: "chevron-double-right",
                disabled: _ctx.currentPage === _ctx.totalPages,
                onClick: _ctx.lastPage
              }, null, 8, ["disabled", "onClick"])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 3
  }, 8, ["title"]))
}