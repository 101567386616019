// import { User } from "../auth/types"


export interface BaseForm {
    description: string;
    id: number;
    questions: Question[];
}

export class Question {
    constructor(
        public formId: number | undefined = undefined,
        public content: string | undefined = undefined,
        public index: number | undefined = undefined,
        public id: number | undefined = undefined,
        public isCondition: boolean | undefined = undefined,
        public answerType: number | undefined = undefined,
        public answerTypeFront: string | undefined = undefined,
        public responseForm: string | undefined = undefined,
        
    ) { }
}
export class DetailsForms {
    constructor(
      public answers: any[],
      public answersParametersResident: string[],
      public answersDetailsResident: string[],
      public answersParametersPersonnel: string[],
      public answersDetailsPersonnel: string[],
      public answersValueResident: number[],
      public answersValuePersonnel: number[],
    ) { }
  }
  

export function findQuestionById(questions: Question[], id: number): Question | undefined {
    return questions.find(question => question.id === id);
}