import { BaseForm, Question, DetailsForms } from "./types";
import { Response } from "../types";
import { AuthenticatedService } from "../auth/AuthenticatedServices";
import { getResponse, keysToSnake } from "../utils";
import { getEnvConfig } from "@/configs/UrlConfig";

export class FormService extends AuthenticatedService {


    async getFormQuestionService(formId: number): Promise<Response<BaseForm>> {
        const params = keysToSnake({ formId });
        const url = `${getEnvConfig().apiURL}/forms/get`;
        return getResponse(url, this.token, "POST", params, undefined, undefined);
    }

    async createFormQuestionService(formId: number,
        content: string,
        index: number,
        answerType: number,
        answerTypeFront: string): Promise<Response<Question>> {
        const params = keysToSnake({
            formId,
            content,
            index,
            answerType,
            answerTypeFront
        });
        const url = `${getEnvConfig().apiURL}/forms/questions/create`;
        return getResponse(url, this.token, "POST", params, undefined, undefined);
    }

    async deleteFormQuestionService( questionId: number) {
        const params = keysToSnake({ questionId });
        const url = `${getEnvConfig().apiURL}/forms/questions/delete`;
        return getResponse(url, this.token, "POST", params, undefined, undefined);
    }

    async updateFormQuestionService( questionId: number, formId: number,
        content: string,
        index: number,
        answerType: number,
        answerTypeFront: string): Promise<Response<Question>> {
        const params = keysToSnake({
            questionId,
            formId,
            content,
            index,
            answerType,
            answerTypeFront
        });
        const url = `${getEnvConfig().apiURL}/forms/questions/edit`;
        return getResponse(url, this.token, "POST", params, undefined, undefined);
    }

    async updateAnswerType(communityId: number, questionId: number, formId: number, answerType: number,
    ): Promise<Response<Question>> {
        const params = keysToSnake({
            questionId,
            formId,
            answerType,
        });
        const url = `${getEnvConfig().apiURL}/form/edit`;
        return getResponse(url, this.token, "POST", params, undefined, undefined);
    }
    async updateAnswerDetails(communityId: number, blockId: number, details: DetailsForms){
        const url = `${getEnvConfig().apiURL}/community/${communityId}/blocks/${blockId}/fill-questionnaire`;
        return getResponse(url, this.token, "POST", keysToSnake(details));
    }
}