import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-wrap" }
const _hoisted_2 = { class: "flex flex-col h-full" }
const _hoisted_3 = { class: "relative" }
const _hoisted_4 = { class: "absolute top-2 right-2 z-10 flex" }
const _hoisted_5 = { class: "overflow-hidden h-40 object-cover" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "flex flex-col h-full gap-4 ml-4" }
const _hoisted_8 = { class: "col-span-12" }
const _hoisted_9 = { class: "text-left" }
const _hoisted_10 = { class: "font-bold text-2xl pt-2 pb-1" }
const _hoisted_11 = { class: "text-sm font-light" }
const _hoisted_12 = { class: "flex gap-4 h-4/6" }
const _hoisted_13 = { class: "flex items-center justify-center m" }
const _hoisted_14 = { class: "flex w-5/6 h-full p-3" }
const _hoisted_15 = { class: "text-left" }
const _hoisted_16 = { class: "text-sm font-normal ml-2 mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mdicon = _resolveComponent("mdicon")!
  const _component_CommunityButton = _resolveComponent("CommunityButton")!
  const _component_ComponentCard = _resolveComponent("ComponentCard")!
  const _component_CommunityRegister = _resolveComponent("CommunityRegister")!
  const _component_ModalAlert = _resolveComponent("ModalAlert")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.communities, (community) => {
      return (_openBlock(), _createBlock(_component_ComponentCard, {
        key: community.id,
        class: "w-80 mr-5 mt-5 h-[380px] pointer transition duration-300 shadow-lg hover:shadow",
        onClick: ($event: any) => (_ctx.showCommunity(community.id)),
        onMouseenter: ($event: any) => (community.isHovered = true),
        onMouseleave: ($event: any) => (community.isHovered = false)
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("section", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                (_ctx.editorView && community.isHovered)
                  ? (_openBlock(), _createBlock(_component_mdicon, {
                      key: 0,
                      name: "delete",
                      onClick: _withModifiers(($event: any) => (_ctx.deleteCommunity(community)), ["stop"]),
                      class: "cursor-pointer px-2 py-2 rounded-full bg-red-600 text-white hover:bg-red-500 focus:bg-red-500"
                    }, null, 8, ["onClick"]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("img", {
                  src: `${_ctx.getEnvConfig().apiURL}/${community.thumbnail}`,
                  class: "w-full h-full object-cover"
                }, null, 8, _hoisted_6)
              ])
            ]),
            _createElementVNode("section", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, _toDisplayString(community.name), 1),
                  _createElementVNode("div", _hoisted_11, _toDisplayString(community.address), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _createVNode(_component_CommunityButton, {
                    communityId: community.id,
                    "round-down": true,
                    "round-top": true,
                    iconName: 'office-building'
                  }, null, 8, ["communityId"])
                ]),
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("div", _hoisted_16, _toDisplayString(community.description), 1)
                  ])
                ])
              ])
            ])
          ])
        ]),
        _: 2
      }, 1032, ["onClick", "onMouseenter", "onMouseleave"]))
    }), 128)),
    (_ctx.showModal)
      ? (_openBlock(), _createBlock(_component_CommunityRegister, {
          key: 0,
          visible: _ctx.showModal,
          communeId: _ctx.communeId,
          action: 'update',
          onAccept: _ctx.handleAccept,
          onClose: _ctx.handleCancelSuccess
        }, null, 8, ["visible", "communeId", "onAccept", "onClose"]))
      : _createCommentVNode("", true),
    (_ctx.success)
      ? (_openBlock(), _createBlock(_component_ModalAlert, {
          key: 1,
          title: _ctx.title,
          message: _ctx.message,
          visible: _ctx.success,
          option: false,
          onAccept: _ctx.handleAcceptSuccess,
          onCancelAlert: _ctx.handleCancelSuccess
        }, null, 8, ["title", "message", "visible", "onAccept", "onCancelAlert"]))
      : _createCommentVNode("", true)
  ]))
}